














































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { formatTimeAgo } from '@/utils/time'
import { audioQueryKeys } from '@/hooks/audio'
import { QueryClient, useQueryClient } from 'vue-query'
import { computed } from '@vue/composition-api'

import get from 'lodash.get'
import Billing from '@/mixins/Billing'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {},
  setup(props: any) {
    const audioTrackId: any = computed(() => props.audioTrack.uid)
    const queryClient = useQueryClient()

    return {
      queryClient,
    }
  },
})
export default class NewMyVideos extends Mixins(Billing) {
  @Prop() audioTrack!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(String) sourceComponent!: string
  @Prop() podcast!: any

  @Getter publicFeedEpisodes!: any

  @Action closeModal!: any
  @Action deleteYoutubeAudio!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action toggleCreditCardAlert!: any

  queryClient!: QueryClient

  mounted() {
    this.$store.commit('addAudio', this.audioTrack)
    sessionStorage.getItem(this.audioTrack.uid) === this.audioTrack.uid &&
      this.queryClient.invalidateQueries(audioQueryKeys.TRACKVIDEOCLIPS)
    sessionStorage.removeItem(this.audioTrack.uid)
  }

  get audioPreviewImage() {
    return this.audioTrack && this.audioTrack.imageInfo ? this.audioTrack.imageInfo.thumbnailURL : null
  }

  get audioDate() {
    return formatTimeAgo(this.audioTrack.createdOn)
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'adori-red' : 'o-20'
  }

  async removeEpisode() {
    this.$store.dispatch('showConfirm', {
      title: 'Remove episode?',
      description: 'Are you sure you want to remove the episode?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.closeModal()
          await this.deleteYoutubeAudio({
            audioUid: this.audioTrack.uid,
            ytFeedUid: this.audioTrack.ytFeed && this.audioTrack.ytFeed.uid,
          })
          this.pushNotification({
            text: 'Episode removed successfully',
            type: 'SUCCESS',
          })
          this.sourceComponent === 'ALLAUDIO-TAB' && this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          if (this.sourceComponent === 'PODCASTS-TAB') {
            this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.podcast.uid}`])
            this.queryClient.invalidateQueries([audioQueryKeys.YTUNPUBLISHED, `${this.podcast.uid}`])
          }
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  // Todo: When implementing for Studio continue here
  handleAudioSelectionIconClicked() {}

  async goToOnboarding() {
    mixpanel.track('New Video')

    webengage?.track('new-video', { action: 'New Video copy will be created' })
    this.$gtag.event('new-video', { action: 'New Video copy will be created' })
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }

    if (this.audioTrack) {
      this.$router.push(`/preview/${this.audioTrack.uid}`)
      return
    }
  }
}
